import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { DEFAULT_DISTANCE_FROM_CONTAINER } from "../constants";
import { adjustLaterallyIfNeeded, adjustVerticallyIfNeeded, defaultPositionAdjustmentIfOverflowsVertically, getIdealBoundingRect, getIdealPositon, isLateralPositionAndOverflowing } from "./utils";
export var getIdealPosition = function getIdealPosition(position) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_DISTANCE_FROM_CONTAINER;
  var idealPosition = {
    newPosition: position
  };
  var idealPositionWithOffset = getIdealPositon(offset);
  if (position === 'bottom') return _objectSpread(_objectSpread({}, idealPosition), {}, {
    top: idealPositionWithOffset
  });
  if (position === 'top') return _objectSpread(_objectSpread({}, idealPosition), {}, {
    bottom: idealPositionWithOffset
  });
  if (position === 'left') return _objectSpread(_objectSpread({}, idealPosition), {}, {
    right: idealPositionWithOffset
  });
  if (position === 'right') return _objectSpread(_objectSpread({}, idealPosition), {}, {
    left: idealPositionWithOffset
  });
  return idealPosition;
};
export var adjustPosition = function adjustPosition(_ref) {
  var content = _ref.content,
    container = _ref.container,
    position = _ref.position,
    offset = _ref.offset;
  var idealRect = getIdealBoundingRect({
    container: container,
    content: content,
    position: position
  });
  if (isLateralPositionAndOverflowing(position, idealRect)) return adjustPosition({
    content: content,
    container: container,
    position: 'top'
  });
  var idealPosition = getIdealPosition(position, offset);
  if (position === 'top' || position === 'bottom') return _objectSpread(_objectSpread(_objectSpread({}, idealPosition), adjustLaterallyIfNeeded(idealRect)), defaultPositionAdjustmentIfOverflowsVertically(idealRect, offset));
  return _objectSpread(_objectSpread({}, idealPosition), adjustVerticallyIfNeeded(idealRect));
};